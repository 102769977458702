import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  Facebook,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Robb Cappelletto"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Robb Cappelletto"
        videoUrl="https://vimeo.com/495902965/577e5e7dab"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"curt"}
                fluid={data.robbCappellettoImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box flex="2">
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Robb Cappelletto
            </Text>
            <Text level="1" mb="1.2rem">
              Guitarist and Educator
            </Text>
            <Box>
              <Link to="https://www.instagram.com/robbcappelletto/" as="a">
                <Instagram mr="1.2rem" />
              </Link>
              <Link to="https://www.facebook.com/robbcappellettomusic/" as="a">
                <Facebook />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Robb Cappelletto is a guitarist, composer and educator based in
              Toronto, Canada. He has two decades of professional teaching
              experience and has been on faculty at{" "}
              <strong>York University</strong> as a jazz guitar instructor since
              2007. An in-demand session player in Toronto's hip-hop, R&B and
              EDM scenes, Robb has recorded and performed with such diverse
              artists as{" "}
              <strong>
                Lord Finesse, Shad, Saukrates, APB, Skratch Bastid, Keys n
                Krates
              </strong>{" "}
              and <strong>A Tribe Called Red</strong>. As a composer and solo
              artist, his music asserts a lyrical and forward-thinking approach
              to the intersection of jazz and progressive rock, while his
              playing with the group re.verse continues to redefine what the
              guitar can do in a hip-hop context.
            </Text>
            <Text level="2">
              “What sets him apart from his peers is his conscientious attention
              to the nuances of tone, and his commitment to building a
              multilayered sonic world in which his music can live.” -{" "}
              <em>The WholeNote</em>
            </Text>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Robb Cappelletto" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    robbCappellettoImage: file(
      relativePath: { eq: "robbCappelletto-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
